import DashboardIcon from "../assets/DashboardIcon.svg";
import DashboardIconActive from "../assets/DashboardIconActive.svg";
import TransactionsIcon from "../assets/TransactionsIcon.svg";
import TransactionsIconActive from "../assets/TransactionsIconActive.svg";
import SecureIcon from "../assets/SecureIcon.svg";
import SecureIconActive from "../assets/SecureIconActive.svg";
import SettingsIcon from "../assets/SettingsIcon.svg";
import SettingsIconActive from "../assets/SettingsIconActive.svg";
import SignOutIcon from "../assets/SignOutIcon.svg";
import AccountsIcon from "../assets/AccountsIcon.svg";
import AccountsIconActive from "../assets/AccountsIconActive.svg";
import Reports from "../components/Reports/Reports.js";

// pages
import Dashboard from "../pages/Dashboard/DashBoard";
import Accounts from "../pages/Account/Accounts.js";
import Transactions from "../pages/Transactions/Transactions.js";
import Secure from "../pages/Secure/Secure.js"
import Settings from "../pages/Settings/Settings.js";
import User from "../components/User/User.jsx";
import CashOrders from "../components/CashFacility/Orders/CashOrders";
import OrdersCash from "../components/CashFacility/Orders/OrdersCash";
import Checkout from "../pages/Checkout/Checkout";

const routes = [
  {
    label: "Dashboard",
    path: "/",
    icon: DashboardIcon,
    activeIcon: DashboardIconActive,
    component: Dashboard,
  },
  // {
  //   label: "Clients",
  //   path: "/accounts",
  //   icon: AccountsIcon,
  //   activeIcon: AccountsIconActive,
  //   component: Accounts,
  // },
  {
    label:"Tickets",
    path: "/transactions",
    icon: TransactionsIcon,
    activeIcon: TransactionsIconActive,
    component: Transactions,
  },
  {
    label:"Reports",
    path: "/reports",
    icon:AccountsIcon,
    activeIcon: AccountsIconActive,
    component: Reports,
  },
 

  // {
  //   label:"Checkout",
  //   path: "/checkout",
  //   icon: TransactionsIcon,
  //   activeIcon: TransactionsIconActive,
  //   component: Checkout,
  // },
  // {
  //   label: "Users",
  //   path: "/user/:userid",
  //   icon: SecureIcon,
  //   activeIcon: SecureIconActive,
  //   component: Secure,
  // },
  // {
  //   label: "Settings",
  //   path: "/settings",
  //   icon: SettingsIcon,
  //   activeIcon: SettingsIconActive,
  //   component: Settings,
  // },

  // {
  //   label: "Cash Orders",
  //   path: "/cashOrders",
  //   icon: AccountsIcon,
  //   activeIcon: AccountsIconActive,
  //   component: OrdersCash,
  // },

  
  // {
  //   label: "User",
  //   path: "/user/:userid",
  //   icon: SignOutIcon,
  //   activeIcon: SignOutIcon,
  //   component:User
  // },
  {
    label: "Sign Out",
    path: "/sign-out",
    icon: SignOutIcon,
    activeIcon: SignOutIcon,
  },
  
];

export default routes;
