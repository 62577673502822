import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
//import "./index.css";
import { Button, Divider } from "@material-ui/core";
import { Box } from "@mui/system";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { axiosInstance } from "../../services/axios";
//import numberFormat from "../../services/CurrencyFormat";



export default function ReviewOrder({ state, handleChange, handleNext,handlePrev ,handleSubmit}) {
  const [price, setprice] = useState(null)
  let {data} = state
  
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  
  let handleSubmitandNext = (e) =>{
    handleNext()
    handleSubmit(e)
  }

  useEffect(() => {
    let get_price = async () =>{

      const priceresponse =  await axiosInstance.get("/price/getprice",{ params: { collectionOffice: data.office } })
      let price  = priceresponse.data.data.price.price
    setprice(price)
    data.total = Number(price)*Number(data.quantity)
    data.price = price
    }
    try {
      get_price()
      
    } catch (error) {
      enqueueSnackbar(`Something went Wrong ,${error.message}`, {
        variant: "error",
      });
      
    }

  }, [])

  

  return (
    <React.Fragment>
      <div className="orderNumber newUserItem">
       
        <Typography variant="h6" gutterBottom>
        Transaction Details:
      </Typography>
      </div>

      <Divider variant="middle" />

      <List className="myList">
        <ListItem sx={{ py: 0, px: 0 }}>
          <ListItemText primary={"Name"} />
          <Typography variant="body2">{data.name}</Typography>
        </ListItem>

        <ListItem sx={{ py: 0, px: 0 }}>
          <ListItemText primary={"Surname"} />
          <Typography variant="body2">{data.surname} </Typography>
        </ListItem>


        <ListItem sx={{ py: 0, px: 0 }}>
          <ListItemText primary={"Email Adress"} />
          <Typography variant="body2">{data.email} </Typography>
        </ListItem>

        <ListItem sx={{ py: 0, px: 0 }}>
          <ListItemText primary={"Phone Number"} />
          <Typography variant="body2">{data.phone} </Typography>
        </ListItem>
        
      </List>
    
      {/* <br/> */}

      
      <div className="orderDetailsContainer">
      

        
        

        
        
      <Divider/>
      <br/>

        <div className = "orderItemContainer buttom-buttons">

        <Button onClick ={handlePrev} variant="contained" color = "secondary" startIcon = {<ArrowBackIcon/>}>Back</Button>
        <Button  onClick ={handleSubmitandNext} variant="contained" color = "primary">PLACE ORDER</Button>

          </div>

      </div>
    </React.Fragment>
  );
}
