import Axios from "axios";


export const getToken = () =>{
    return localStorage.getItem("token")
}

export const setToken = (token) =>{
    localStorage.setItem("token",token)
}

export const axiosInstance = Axios.create({ 
   // baseURL: "https://kamili-fuel.herokuapp.com",
    // baseURL: "https://dcd-ticketsonline.herokuapp.com",
    baseURL:"https://onlinecombinedbackend.onrender.com",
    // headers: {
    //     'Authorization': `Bearer `+ getToken(),
    //     // "Access-Control-Allow-Origin": "*"
    // }
 });

 export const cashaxiosInstance = Axios.create({ 
   // baseURL: "https://kamilicashtesting.herokuapp.com",
     baseURL: "http://localhost:5000",
    headers: {
        'Authorization': `Bearer `+ getToken(),
        // "Access-Control-Allow-Origin": "*"
    }
 });


