import * as React from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { withStyles } from "@material-ui/core/styles";
import './Reports.css'
import { ExportToCsv } from 'export-to-csv'; 

import { Button, Typography } from "@material-ui/core";
import { useState, useEffect } from 'react';
import { axiosInstance } from '../../services/axios.js';



const Reports = () => {

let data ;



  let get_all_users = async () =>{
    try {
      const response =  await axiosInstance.get("/api/district/all")
    let users = response.data
    // console.log(response.data)
  

    let customisedUsers = users.map(user =>({
      id:user.id,
      standno:user.standno,
      type:user.type,
      size:user.size,
      address:user.address,
      currentdoc:user.currentdoc,
    
      district:user.district,
      province:user.province
      
      
    }))

    data=customisedUsers
   
      
    } catch (error) {
      console.log(error)
      
    }

  }


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'left',
  color: theme.palette.text.secondary,
}));


const WhiteTextTypography = withStyles({
  root: {
    color: "#3cc33e"
  }
})(Typography);

//amount

const columns=[ {
    field:'amount',
  title: 'amount',
    width: 150,
   
  },
  {
    field:'currency',
  title: 'currency',
    width: 150,
   
  },
  {
    field:'datecreated',
  title: 'datecreated',
    width: 150,
   
  },
  {
    field:'doneby',
  title: 'doneby',
    width: 150,
   
  },
  {
    field:'email',
  title: 'email',
    width: 150,
   
  },
  {
    field:'idnum',
  title: 'idnum',
    width: 150,
   
  },
  {
    field:'name',
  title: 'name',
    width: 150,
   
  },
  {
    field:'phone',
  title: 'phone',
    width: 150,
   
  },
  {
    field:'size',
  title: 'size',
    width: 150,
   
  },
  {
    field:'surname',
  title: 'surname',
    width: 150,
   
  },
  {
    field:'tid',
  title: 'tid',
    width: 150,
   
  },
  {
    field:'tname',
  title: 'tname',
    width: 150,
   
  },
  {
    field:'transid',
  title: 'transid',
    width: 150,
   
  },]

const columnss = [
 
   
      // {
      //   field:'doneby',
      // title: 'District',
      //   width: 150,
       
      // },
      {
        field: 'transid',
        headerName: 'transid',
        width: 100,
  
      },
      {
        field: 'name',
        headerName: 'name',
        width: 100,
  
      },
      {
        field: 'surname',
        headerName: 'surname',
        width: 100,
  
      },
      {
        field: 'phone',
        headerName: 'phone',
        width:150,
       
    },
    {
      field: 'idnum',
      headerName: 'idnum',
      width:180,
     
  },
 
  {
    field: 'email',
    headerName: 'email',
    width:150,
   
},

      {
          field: 'amount',
          headerName: 'amount',
          width:150,
         
      },
    
   
   
  
   
  ];


const csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columnss.map((c) => c.headerName),
  };
  
  const csvExporter = new ExportToCsv(csvOptions);
  

  const handleExportData = async () => {
    try {
        const response = await axiosInstance.get("/admin/gettrans");
        let orders = response.data.data.transactions;
         console.log(orders)
  
        let customisedOrders = orders.map((orders) => ({
          ...orders,
          
          date:orders.datecreated,
          ticketnum:orders.transid,
          name:orders.name,
          surname:orders.surname,
          phone:orders.phone,
          email:orders.email,
          
          ticketname:orders.tname,
          amount:orders.amount,
          currency:orders.currency,
        

        }));
        data=customisedOrders
       
       csvExporter.generateCsv(orders);
      } catch (error) {
        console.log(error);
      }
    }
  
    
  


      const LeaseData = async () => {
        try {
            const response = await axiosInstance.get("/admin/whatsapp");
            let orders = response.data.data.transactions;
             console.log(orders)
      
            let customisedOrders = orders.map((orders) => ({
              ...orders,
              
              date:orders.datecreated,
              ticketnum:orders.transid,
              name:orders.name,
              surname:orders.surname,
              phone:orders.phone,
              email:orders.email,
              
              ticketname:orders.tname,
              amount:orders.amount,
              currency:orders.currency,
            
    
            }));
            data=customisedOrders
           
           csvExporter.generateCsv(orders);
          } catch (error) {
            console.log(error);
          }
          };
          const Cession = async () => {
            try {
                const response = await axiosInstance.get("/admin/website");
                let orders = response.data.data.transactions;
                 console.log(orders)
          
                let customisedOrders = orders.map((orders) => ({
                  ...orders,
                  
                  date:orders.datecreated,
                  ticketnum:orders.transid,
                  name:orders.name,
                  surname:orders.surname,
                  phone:orders.phone,
                  email:orders.email,
                  
                  ticketname:orders.tname,
                  amount:orders.amount,
                  currency:orders.currency,
                
        
                }));
                data=customisedOrders
               
               csvExporter.generateCsv(orders);
              } catch (error) {
                console.log(error);
              }
              };

              const Correspondances = async () => {
                try {
                  const response =  await axiosInstance.get("/api/report/correspondances")
                let users = response.data
                // console.log(response.data)
              
            
                let customisedUsers = users.map(user =>({
                  
                  standno:user.standno,
                  type:user.type,
                  size:user.size,
                  address:user.address,
                  currentdoc:user.currentdoc,
                
                  district:user.district,
                  province:user.province
                  
                }))
            
                data=customisedUsers
               
                  
                } catch (error) {
                  console.log(error)
                  
                }
            
              
                    csvExporter.generateCsv(data);
                  };
    
                  const Sale = async () => {
                    try {
                      const response =  await axiosInstance.get("/api/report/sale")
                    let users = response.data
                    // console.log(response.data)
                  
                
                    let customisedUsers = users.map(user =>({
                      
                      standno:user.standno,
                      type:user.type,
                      size:user.size,
                      address:user.address,
                      currentdoc:user.currentdoc,
                    
                      district:user.district,
                      province:user.province
                      
                    }))
                
                    data=customisedUsers
                   
                      
                    } catch (error) {
                      console.log(error)
                      
                    }
                
                  
                        csvExporter.generateCsv(data);
                      };
        
  return (
    <div className='box'>
    <Box sx={{ flexGrow: 1 }}>
     
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        
          <Grid item xs={2} sm={4} md={4}  >
            <Item>
            <WhiteTextTypography variant="h5">
        Registered Delegates
      </WhiteTextTypography>
            <Typography variant ="h7">Report on all registered delegates</Typography>
           
            </Item>
            <Item> <Button  color = "primary"className="editOrder" variant = "outlined" onClick={handleExportData}>DOWNLOAD</Button> </Item>
       
          </Grid>
        
          <Grid item xs={2} sm={4} md={4} >
          <Item>
            <WhiteTextTypography variant="h5">
        Whatsapp
      </WhiteTextTypography>
            
            <Typography variant ="h7">Registered Delegates #Whatsapp</Typography>
           
            </Item>
            <Item> <Button  color = "primary"className="editOrder" variant = "outlined" onClick={LeaseData}>DOWNLOAD</Button> </Item>
       
          </Grid>
        
          <Grid item xs={2} sm={4} md={4}  >
          <Item>
            <WhiteTextTypography variant="h5">
        Website
      </WhiteTextTypography>
            
            <Typography variant ="h7">Registerd Delegates #Website</Typography>
           
            </Item>
            <Item> <Button  color = "primary"className="editOrder" variant = "outlined" onClick={Cession}>DOWNLOAD</Button> </Item>
       
          </Grid>

          

         
        
      </Grid>
    
    </Box>
    </div>
  );
}

export default Reports