
import "./TransactionList.css";
import { useState ,useEffect} from "react";
import { DataGrid } from "@material-ui/data-grid";
import { Link } from "react-router-dom";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { orders } from "../../utils/graphData.js";
import { axiosInstance } from '../../services/axios.js';


import { Button, Typography } from "@material-ui/core";
import MaterialTable from "material-table";
import numberFormat from "../../utils/CurrencyFormat";
import formartDate from "../../utils/formatDate";

import { Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import Col from 'react-bootstrap/Col';
import QRCode from "react-qr-code";

import Table from 'react-bootstrap/Table';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf'
import Container from 'react-bootstrap/Container';
import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';

const Transaction = () => {
  const [rorders, setROrders] = useState([]);
  const handleDelete = (id) => {
    setROrders(orders.filter((item) => item.id !== id));
  };


  class ComponentToPrint extends React.Component{

   
    render() {
        
      return(
        <div id='printOnly'>
    
        <Container>
          <Row><div>printing</div></Row>
          <Row><div>.</div></Row>
         
         
         
        <Row>
        <div className="fw-bolder" id="prin">
          
                    <h5 className="fw-bold " >Elders Deeperlife</h5>
                    <h6 className="fw-bold "  id="Invoicenum">
                      Ticket #: {id}
                      
                    </h6>
                    
                  </div>
        </Row>
        <Row>
         <Col> 
         {/* <div className="fw-bold">Details:</div> */}
                      {/* `<div id="billto"> <h6>name : {name}</h6></div>` */}
                      <div id="billto" className="fw-bold mt-2"> <h7>Tittle: {title}</h7></div>
                      <div className="fw-bold mt-2"><h11>  {name} </h11></div>
                      <div className="fw-bold mt-2"><h11>  {surname}</h11></div>
                      <div ></div></Col>
         <Col> <div className="fw-bold mt-2"><div style={{ height: "auto", margin: "0 auto", maxWidth: 64, width: "100%" }}>
        <QRCode
        size={256}
        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
        value={name}
        viewBox={`0 0 256 256`}
        />
        </div></div></Col>
        
        </Row>
      
       
        
        </Container>
        </div>
      )
  }
  }
  
  const style = {
    position: 'absolute' ,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,

    width:'50%' ,
    height: '50%',
  
    
  };
  
  const [name, setName] = useState([]);
  const [surname, setSurname] = useState([]);
  const [id, setID] = useState([]);
  const [count ,setCount]=useState([]);
  const [title, setTitle] = useState([]);
  const [ticketname, setTicketname] = useState([]);

  const componentRef = useRef();






  useEffect(async () => {

    let get_all_orders = async () =>{
      try {
        const response = await axiosInstance.get("/admin/gettrans");
        let orders = response.data.data.transactions;
         console.log(orders)
  
        let customisedOrders = orders.map((orders) => ({
          ...orders,
          id:orders._id,
          date:orders.datecreated,
          ticketnum:orders.transid,
          name:orders.name,
          surname:orders.surname,
          phone:orders.phone,
          email:orders.email,
          gender:orders.gender,
          ticketname:orders.tname,
          amount:orders.amount,
          currency:orders.currency,
          district:orders.districtname

        }));
        setROrders(customisedOrders);
      } catch (error) {
        console.log(error);
      }
    }
    get_all_orders()
    
  }, []);

  const columns = [
    { field: "id", title: "ID", width: 90 , hidden:true },
    {field:"date",title:"date"},
    {
      field: "ticketnum",
      title: "Ticket #",
    },
    {
      field: "name",
      title: "Name",
      //   description: 'This column has a value getter and is not sortable.',
      width: 200,
    },
    {
      field: "surname",
      title: " Surname",
      //   description: 'This column has a value getter and is not sortable.',
      width: 200,
    },
    {
      field: "phone",
      title: "Phone",
      
    },
    {
      field: "email",
      title: "Email",
      width: 150,
    },
    {
      field: "idnum",
      title: "ID",
    },
    {
      field: "ticketname",
      title: "ticketname",
    },
    {
      field: "doneby",
      title: "Reg mode",
    },
    {
      field: "amount",
      title: "Amount",
    },
    {
      field: "currency",
      title: "Currency",
    },
    // {
    //   field: "district",
    //   title: "District",
    // },
    
   
    // {
    //   field: "action",
    //   title: "Action",
    //   width:150,
    //   render: (params) => {
    //     return (
    //       <>
    //         <Link to={"/order/" + params.id}>
    //           <Button  color = "primary"className="editOrder" variant = "outlined">Edit</Button>
    //         </Link>

    //         {/* <DeleteOutlinedIcon
    //           className="deleteOrder"
    //           onClick={() => handleDelete(params.id)}
    //         /> */}
    //       </>
    //     );
    //   },
    // },
  //   {
  //     field:"id",
  //     title:"Action",
  //       width:150,
  //       render:(params)=>{
  //         return(
  //             <>
  //             <div>
  //   <ReactToPrint
  //     trigger={() => <Button variant="outlined">Print this out!</Button>}
  //     content={() => componentRef.current}
  //   />
  //   <ComponentToPrint ref={componentRef} />
  // </div>
  //             </>)}

  //    }
   
  ];



  

  return (
    <div className="productList">
      <div className="topPart">
        <div className="title">
          <Typography variant="h5">All Tickets</Typography>
        </div>
       
      </div>
      <div className = "bottomPart">
        <MaterialTable
        title="All Tickets"
          
          data={rorders}
          disableSelectionOnClick
          
          columns={columns}
          onRowClick={(event, rowData) =>{setTitle(rowData.tittle);setName(rowData.name);setSurname(rowData.surname);setID(rowData.transid);setTicketname(rowData.tname)}}
          options={{
            pageSize:5,
              exportButton: true,
              maxBodyHeight:"60vh",
              headerStyle: {
                backgroundColor: '#01579b',
                color: '#FFF',
                display:"fixed"
             }
          }}
        />

      </div>
      
    </div>
  );
};

export default Transaction;
