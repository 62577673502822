import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import axios from 'axios'
import "./index.css";
import { axiosInstance } from "../../services/axios";

const AccountStatement = ({user,trnx}) => {

  return (

    <div>
      <MaterialTable
        title= { `Statement of Accounts :${user.district}`}
        columns={[
          { title: "datecreated", field: "datecreated",sorting:false},
          { title: "ticket#", field: "transid" ,sorting:false},
          { title: "amount", field: "amount",sorting:false },
          { title: "name", field: "name",sorting:false },
          { title: "surname", field: "surname",sorting:false },
          { title: "phone", field: "phone",sorting:false },
          { title: "tname", field: "tname",sorting:false },
          // { title: "Balance", field: "balance",sorting:false },
        ]}
        data={trnx}
        options={{
          search: true,
          exportButton: true,
          sorting: true,
        }}
      />
    </div>
  );
};

export default AccountStatement;
