import React from 'react'

import FeatureInfor from '../../components/FeatureInfor/FeatureInfor.jsx'
import './DashBoard.css'

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
//import logo from '../../images/logoweb.PNG';
import logo from '../../images/flyrr.jpeg';
import { Link } from "react-router-dom";

import { StoreContext } from '../../utils/store'


export default function DashBoard() {
 //const set = () => {

 
 
   
  
     
  
    return (
        <div className ="rootApp" >
            <div className ='root1'>
            <FeatureInfor/>
            
            {/* <Chart data = {salesData} title = "Daily Sales" grid dataKey = "sales"/> */}
            </div>

            <div className ='root'>

            <div className ="featured">
            <div className="featureItem">
                {/* <span className="featuredTitle">
                    Number Of Customers
                </span>
                <div className="featureContainer">
                    <span className="total">200</span>
                    <span className="Rate"> 
                    +2% 
                    <ArrowUpward className = "featureIcon featureIcon-positive"/>
                    </span>
                </div>
                <span className="sub">compare to Last week</span> */}

<Card sx={{ maxWidth: 345 }}>
      <CardMedia
        sx={{ height: 300 ,width:340}}
        image={logo}
        title="green iguana"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Wisemen Conference
        </Typography>
        <Typography variant="body2" color="text.secondary">
        USD200
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" component={Link} to={"/all/"+"USD200"+"/"+"1"} >Manage</Button>
        
        
      </CardActions>
    </Card>
            </div>
            <div className="featureItem">
            <Card sx={{ maxWidth: 345 }}>
      <CardMedia
       sx={{ height: 300 ,width:340}}
        image={logo}
        title="green iguana"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Wisemen Conference
        </Typography>
        <Typography variant="body2" color="text.secondary">
        USD150
        </Typography>
      </CardContent>
      <CardActions>
      <Button size="small" component={Link} to={"/all/"+"USD150"+"/"+"1"} >Manage</Button>
        
        
      </CardActions>
    </Card>
            </div>

            <div className="featureItem">
            <Card sx={{ maxWidth: 345 }}>
      <CardMedia
        sx={{ height: 300 ,width:340}}
        image={logo}
        title="green iguana"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
         Wisemen Conference
        </Typography>
        <Typography variant="body2" color="text.secondary">
       USD100
        </Typography>
      </CardContent>
      <CardActions>
      <Button size="small" component={Link} to={"/all/"+"USD100"+"/"+"1"} >Manage</Button>
        
      </CardActions>
    </Card>
            </div>

            

          

           
            
        </div>
        <div>.</div>

        <div className ="featured">

        <div className="featureItem">
            <Card sx={{ maxWidth: 345 }}>
      <CardMedia
        sx={{ height: 300 ,width:340}}
        image={logo}
        title="green iguana"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
         Wisemen Conference
        </Typography>
        <Typography variant="body2" color="text.secondary">
       USD50
        </Typography>
      </CardContent>
      <CardActions>
      <Button size="small" component={Link} to={"/all/"+"USD50"+"/"+"1"} >Manage</Button>
        
      </CardActions>
    </Card>
            </div>


            <div className="featureItem">
            <Card sx={{ maxWidth: 345 }}>
      <CardMedia
        sx={{ height: 300 ,width:340}}
        image={logo}
        title="green iguana"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
         Wisemen Conference
        </Typography>
        <Typography variant="body2" color="text.secondary">
       USD25
        </Typography>
      </CardContent>
      <CardActions>
      <Button size="small" component={Link} to={"/all/"+"USD25"+"/"+"1"} >Manage</Button>
        
      </CardActions>
    </Card>
            </div>

            <div className="featureItem">
            <Card sx={{ maxWidth: 345 }}>
      <CardMedia
        sx={{ height: 300 ,width:340}}
        image={logo}
        title="green iguana"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
         Wisemen Conference
        </Typography>
        <Typography variant="body2" color="text.secondary">
       USD10
        </Typography>
      </CardContent>
      <CardActions>
      <Button size="small" component={Link} to={"/all/"+"USD10"+"/"+"1"} >Manage</Button>
        
      </CardActions>
    </Card>
            </div>



</div>


<div>.</div>

<div className ="featured">

<div className="featureItem">
    <Card sx={{ maxWidth: 345 }}>
<CardMedia
sx={{ height: 300 ,width:340}}
image={logo}
title="green iguana"
/>
<CardContent>
<Typography gutterBottom variant="h5" component="div">
 Wisemen Conference
</Typography>
<Typography variant="body2" color="text.secondary">
Online
</Typography>
</CardContent>
<CardActions>
<Button size="small" component={Link} to={"/all/"+"online"+"/"+"1"} >Manage</Button>

</CardActions>
</Card>
    </div>


   

 


</div>





            </div>
           
        </div>



    )
}
  




