import './AllCashDeposits.css'
import {  useEffect, useState } from 'react'
import { DataGrid } from '@material-ui/data-grid';
import {Link} from 'react-router-dom'
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

// import NewDeposit from './NewDeposit/NewDeposit';

import React from 'react'
// import { deposits } from '../../utils/graphData'
import { deposits } from '../../../../utils/graphData'
import { Typography } from '@material-ui/core';
// import { axiosInstance } from '../../services/axios';
import { axiosInstance } from '../../../../services/axios';
import { cashaxiosInstance } from '../../../../services/axios';
import MaterialTable from 'material-table';
// import { formartDate } from '../../utils/formartDate';
import { formartDate } from '../../../../utils/formatDate';
// import { numberFormat } from '../../../../utils/CurrencyFormat';
import numberFormat from '../../../../utils/CurrencyFormat';
import NewCashDeposit from './NewCashDeposit/NewCashDeposit';

export default function AllCashDeposits() {


    const [orders, setOrders] = useState([])
    const handleDelete = (id) =>{
        setOrders(orders.filter((item) => item.id !== id));
    }
    useEffect( async () => {
      let get_all_deposits =  async () =>{
        try {
          const response =  await cashaxiosInstance.get("/admin/cash/allCashDeposits")
          console.log(response.data.message);
          console.log(response.data.data.cashDeposits);
          let deposits = response.data.data.cashDeposits;
      
  
        let customiseDeposits = deposits.map( function(deposit) {
          // let mdate = deposit.date
          // mdate.toLocaleTimeString()
          
          
          return{
          id:deposit._id,
          date: deposit.createdAt,
          currency:deposit.currency,
          amount:numberFormat(deposit.amount),
          status:deposit.status,
          declineReason:deposit.decline_reason,
          loadedBy:deposit.loaded_by,
          reference:deposit.reference,
          bank:deposit.bank,
          companyname:deposit.companyName,
          charge:deposit.charge,
          actualDepositAmount:numberFormat(deposit.actualDepositAmount),
          charged:numberFormat(deposit.chargeAmount)

  
        }
      })
        console.log(customiseDeposits)
        setOrders(customiseDeposits)
          
        } catch (error) {
          console.log(error)
          
        }
        
      }
      get_all_deposits()
      
    }, [])
    

    const columns = [
      //  { field: 'id',title: 'ID', width: 20,hidden:true },
        {
          field:'date',
        title: 'Date & Time',
          width: 150,
         
        },
        {
          field:'companyname',
        title: 'CompanyName',
          width: 150,
         
        },
        {
          field: 'currency',
        title: 'Ccy',
          width: 100,
    
        },
        {
          field: 'actualDepositAmount',
        title: 'DepositAmount',
          width:150,
         
      },
      
      {
        field: 'charge',
      title: 'Charge(%)',
        width:150,
       
    },
    {
      field: 'charged',
    title: 'Charge',
      width:150,
     
  },


        {
            field: 'amount',
          title: 'Amt',
            width:150,
           
        },
        {
          field: 'status',
        title: 'Status',
        //   description: 'This column has a value getter and is not sortable.',
        render: (params) => {
          return (
            <> 
            <p 
            className = {params.status}
            >
            {params.status}
            </p>
            
            </>
          
          );
        },
        },
        {
            field: 'decline_reason"',
          title: 'Decline Reason',
          //   description: 'This column has a value getter and is not sortable.',
            width: 150,
        },
        {
            field: 'loadedBy',
          title: 'Loaded-By',
          //   description: 'This column has a value getter and is not sortable.',
            width: 200,
        },
        {
            field: 'bank',
          title: 'Bank',
          //   description: 'This column has a value getter and is not sortable.',
            width: 200,
        },
        {
            field: 'reference',
          title: 'Ref',
          //   description: 'This column has a value getter and is not sortable.',
            width: 100,
        },
        {
          field:"id",
          title:"Action",
            width:150,
            render:(params)=>{
                return(
                    <>
                        <Link to = {"/deposit/"+params.id}>
                            <button className = "editDeposit btn">Edit</button>
                        </Link>
                        
                        {/* <DeleteOutlinedIcon 
                        className = "deleteDeposit" 
                        onClick = {
                            () => handleDelete(params.id)
                            }
                            /> */}
                    </>
                )
            },
        }
      ];
    
    return (
        <div className= "depositList">
            <div className="topPart"> 
                <div className="title">
                    <Typography variant ="h5">Cash Deposits</Typography>
                </div>
                <button className="addCashDeposit"><NewCashDeposit/></button>
            </div>
            
            <div className = "bottomPart">
            <MaterialTable
              title = "All Deposit as at 25/09/21"
                data={orders}
                columns={columns}
                options= {{
                  maxBodyHeight:"60vh",
                  pageSize:5,
                  exportButton: true,
                  headerStyle: {
                    backgroundColor: '#525c6b',
                    color: '#FFF',
                    display:"fixed"
                 }
      
                }}
            />

            </div>
            
        </div>
    )
    
}


