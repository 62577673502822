import React, { useState } from "react";
import "./Topbar.css";
import Badge from "@material-ui/core/Badge";
import { Link } from "react-router-dom";
import logo from '../../../images/logoweb.PNG'
import { useHistory } from "react-router-dom";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { styled, alpha } from '@mui/material/styles';
import CashFee from '../../CashFacility/Setfee/CashFee';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';

import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";

import {
  Avatar,
  IconButton,
  Button,
  List,
  ListItem,
  Typography,
} from "@material-ui/core";
import { fontSize } from "@mui/system";


const TopBar = () => {


 
  const history = useHistory()
  const [active, setActivePage] = useState(false);
  const changeActivePage = (e) => {
    let id = e.target.id
    setActivePage(id)


  };

  const logout = () => {
    localStorage.clear();
    history.push("/sign-in")
  }
  ///const popupState = usePopupState({ variant: 'popover', popupId: 'demoMenu' })

  
  
  return (
    <div className="topbar">
      <div className="topbarWrapper">
        <div className="topLeft">
          <div className="logoimg">
            <span>{<img className="img" src={logo} />} </span>
          </div>

          <div className="logotext">
            <span className="logo">ZAOGA F.I.F</span>
          </div>
        </div>





        <div className="topCenterFlex">
          <div
          // className={active === "deposits" ? "topCenterActive" : undefined}
          >
            <div >
            </div>
            {/* <ListItem
              button
              component={Link}
              to="/deposits"
              id="deposits"
              onClick={changeActivePage}
            >

              Deposits
            </ListItem> */}
            {/* <PopupState variant="popover" popupId="demo-popup-menu">
              {(popupState) => (
                <React.Fragment>
                  <Button
                    style={{

                      borderColor: "blue",
                      color: "white",
                      textTransform: "none",
                      fontSize: "18px"

                    }} variant="text" {...bindTrigger(popupState)}>
                    Diesel
                  </Button>
                  <Menu {...bindMenu(popupState)}>
                    <MenuItem
                      component={Link}
                      to="/deposits"
                      id="deposits"
                      onClick={popupState.close}>

                      {<MonetizationOnIcon />}
                      Deposits</MenuItem>
                    <Divider sx={{ my: 0.5 }} />
                    <MenuItem
                      id="orders"
                      component={Link}
                      to="/transactions"
                      onClick={popupState.close}>

                      {<ShoppingCartIcon />}
                      Orders</MenuItem>

                  </Menu>
                </React.Fragment>
              )}
            </PopupState> */}



          </div>

          <div className={active === "accounts" ? "topCenterActive" : undefined}>
            {/* { <ListItem
              button
              component={Link}
              id="accounts"
              to="/accounts"
            classname="clientitem"
              onClick={changeActivePage}
            >
              Clients
            </ListItem> */}
            <Button style={{

              borderColor: "blue",
              color: "white",
              textTransform: "none",
              fontSize: "18px"

            }} component={Link}
              id="accounts"
              to="/accounts"
              onClick={changeActivePage} variant="text">.</Button>

          </div>




          <div className={active === "orders" ? "topCenterActive" : undefined}>
            {/* <PopupState variant="popover" popupId="demo-popup-menu">
              {(popupState) => (
                <React.Fragment>
                  <Button style={{

                    borderColor: "blue",
                    color: "white",
                    textTransform: "none",
                    fontSize: "18px"


                  }} variant="text" {...bindTrigger(popupState)}>
                    Cash
                  </Button>
                  <Menu {...bindMenu(popupState)}>
                    <MenuItem id="cashdeposits"
                      component={Link}
                      to="/allcashdeposits"
                      onClick={
                        popupState.close
                      }>
                      {<MonetizationOnIcon />}
                      Deposits</MenuItem>
                    <Divider sx={{ my: 0.5 }} />
                    <MenuItem
                      id="cashorders"
                      component={Link}
                      to="/cashOrders"
                      onClick={popupState.close}
                    >
                      {<ShoppingCartIcon />}  Orders</MenuItem>
                      <Divider sx={{ my: 0.5 }} />
                     
                      <MenuItem
                     id="setfee"
                     component={CashFee}
                     
                    >
                      {<SettingsApplicationsIcon/>}Set Fee</MenuItem>
                      

                  </Menu>
                </React.Fragment>
              )}
            </PopupState> */}
            <div>

            </div>

            
            
            {/* <ListItem
              button
              id="orders"
              component={Link}
              to="/transactions"
              onClick={changeActivePage}
            >
              Orders
            </ListItem> */}
          </div>

          <div className={active === "accounts" ? "topCenterActive" : undefined}>
            {/* { <ListItem
              button
              component={Link}
              id="accounts"
              to="/accounts"
            classname="clientitem"
              onClick={changeActivePage}
            >
              Clients
            </ListItem> */}
            <Button style={{

              borderColor: "blue",
              color: "white",
              textTransform: "none",
              fontSize: "18px"

            }} component={Link}
              id="accounts"
              to="/settings"
              onClick={changeActivePage} variant="text">.</Button>

          </div>
          
        </div>

        <div className="topRight">
          <div className="topbarIcons">
            <IconButton>
              <Badge badgeContent={4} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>

            <IconButton>
              <Avatar>KL</Avatar>
            </IconButton>
            <form onSubmit={logout}>
              <div className="logout">

                {/* <IconButton>
              <Badge badgeContent={4} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton> */}

                <Button variant="button" type="submit" onClick={logout}>
                  <Typography>


                    Logout
                  </Typography>
                </Button>

              </div>
            </form>
          </div>

        </div>
      </div>
    </div>
  );
};

export default TopBar;
