import React from 'react'
import './FeatureInfor.css'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';

const FeatureInfor = () => {
    return (
        <div className ="featured">
            <div className="featureItem">
                <span className="featuredTitle">
                    Ticket Category
                </span>
                <div className="featureContainer">
                    {/* <span className="total">{localStorage.getItem("ticket")}</span> */}
                    <span className="total">6</span>
                    
                </div>
               
            </div>
            <div className="featureItem">
                <span className="featuredTitle">
                    Tickets Sold
                </span>
                <div className="featureContainer">
                    <span className="total">{localStorage.getItem("trans")}</span>
                    
                </div>
               
            </div>

            {/* <div className="featureItem">
                <span className="featuredTitle">
                    Total Districts
                </span>
                <div className="featureContainer">
                    <span className="total"> {localStorage.getItem("dis")}</span>
                    
                </div>
               
            </div> */}

            <div className="featureItem">
                <span className="featuredTitle">
                    Revenue Collected
                </span>
                <div className="featureContainer">
                    <span className="total">USD{localStorage.getItem("us")}</span>
                    
                </div>
                
            </div>
            
        </div>

        
    )
}

export default FeatureInfor
