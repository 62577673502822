import { CircularProgress, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { axiosInstance } from "../../services/axios";
import { useSnackbar } from "notistack";

const FinalStep = ({ state }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [ordernumber, setordernumber] = useState(null);
  let { data } = state;

  useEffect(() => {
    // post the other to backend and create one
    const create_order = async () => {
      let post_data = {
        order_amount: data.total,
        order_collection_office: `${data.office}`,
        driver: `${data.driver["firstname"]} ${data.driver["lastname"]}`,
        truck: `${data.truck}`,
        trailer: `${data.trailer}`,
        trailer2: "n/a",
        passport:`${data.driver['passport']}`,
        quantity:`${data.quantity}`,
        price:`${data.price}`
      };
      try {
        let order_responce = await axiosInstance.post(
          "/order/create",
          post_data
        );
        let order = order_responce.data;
        let success = order.success;
      
        if (success === "true") {
          enqueueSnackbar("Order was successfully created", {
            variant: "success",
          });
          setordernumber(order.data.order.order_id);
        } else {
          let message = order.message
          enqueueSnackbar(`${message}`, {
            variant: "warning",
          });
        
        }
      } catch (error) {
        enqueueSnackbar(`${error.message}`, { variant: "error" });
      }
    };
    try {
      create_order();
    } catch (error) {
      enqueueSnackbar(`${error.message}`, { variant: "error" });
    }
  }, []);
  return (
    <div>
      <React.Fragment>
        <Typography variant="h5" gutterBottom>
          Thank you for your order.
        </Typography>
        <hr/>
        <Typography variant="subtitle1">
          Transaction Staus:
          <br/>
          <Typography variant="h6" style={{ fontWeight: 600 }}>
            {
              ordernumber === null?(<CircularProgress />):(
                <>
                {ordernumber}
                </>
                )
            }
          
          </Typography>
          <br/>
          <hr/>

        </Typography>
        <Typography>
          {/* {JSON.stringify(data, null, 4)}
          {data.driver["firstname"]} */}
          If the transaction status is successful .Your ticket will be sent to your email
        </Typography>
      </React.Fragment>
    </div>
  );
};

export default FinalStep;