import { Button, FormControl, InputLabel, MenuItem, Paper, Select, TextField, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import './index.css'
import { axiosInstance } from "../../../services/axios";


const EditDeposit = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  let params = useParams();
  const [depositnumber, setdepositnumber] = useState(params.depositid);
 
  const [state, setState] = React.useState('');
  const [formData, setformData] = useState({});
  
  
 

  const handleChange = (e) => {
    setformData({ ...formData, [e.target.id]: e.target.value.trim() });
    
    // console.log(e.target)

  };
  //const handleSubmit =  async(event) => 
  const handleSubmit = async(e) =>{
      e.preventDefault()
     // const data = new FormData(event.currentTarget);
      // return console.log( { "depositId":depositnumber,...formData})
      try {
        let responce =  await axiosInstance.post('/deposit/update',
        { "depositId":depositnumber,...formData ,
        
          }
        )
        console.log(params.new_value)
        console.log(responce.data.message)
        let my_responce = responce.data.success
      

        if(my_responce === true){
            enqueueSnackbar('Deposit value edited  Successfully',{variant: "success"});
        }
        else{
            enqueueSnackbar('Something went Wrong',{variant: "warning"});

        }
          
      } catch (error) {
        enqueueSnackbar(`${error.message}`,{variant: "error"});
      }
  }


  return (
    <div className="mainorderpage">
      <div className="myheading">
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
        >
          Update Deposit
        </Typography>
        <Typography>
                Deposit id :{params.depositid}
        </Typography>
        <br/>
        

        <FormControl fullWidth >
            
          {/* <InputLabel id="demo-simple-select-label">Status</InputLabel> */}
          {/* <Select
            labelId="state"
            // variant="outlined"
            id="mystate"
            value= {formData.undefined}
            label="state"
            onChange={handleChange}
          >
            <MenuItem  value ="pending">pending</MenuItem>
            <MenuItem value="complete">complete</MenuItem>
            <MenuItem  value="cancelled">cancelled</MenuItem>
          </Select> */}

          <br/>
          <br/>

          <TextField
              variant="outlined"
              autoComplete="false"
              // value={data["quantity"]}
              type="number"
              label="new value"
              id="new_value"
              name="new_value"
              onChange={handleChange}
              helperText="new values of deposit"
              // fullWidth
            ></TextField>
            <br/>
          <Button onClick = {handleSubmit} variant = "contained" color = "primary">
              Update
          </Button>
        </FormControl>
      </div>
    </div>
  );
};

export default EditDeposit;
