import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { SnackbarProvider } from "notistack";
import StoreProvider from './utils/store'

ReactDOM.render(
 
  <React.StrictMode>
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <StoreProvider>
      <App />
      </StoreProvider>
    </SnackbarProvider>
  </React.StrictMode>
 ,
  document.getElementById("root")
);
