import "./NewOrder.css"
import React from 'react'

const NewOrder = () => {
    return (
        <div className = "newOrder">
            new Order Page
        .......................comming soon.............
            

            
        </div>
    )
}

export default NewOrder
