import React, { useEffect, useState } from "react";
import "./Ad.css";
import Select from "react-select";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Switch,
  Typography,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import { axiosInstance } from "../../services/axios";

const AdressForm = ({ state, handleChange, handleNext,handleQuintityChange }) => {

  const {data} = state

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [formData, setFormData] = useState({});
  


  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);

    try {
      let response = await axiosInstance.post("/auth/register", formData);
      if (response) {
        let success = response.data.success;
        console.log(response.data);
        if (success === "true") {
          enqueueSnackbar("Driver Creation was successful", {
            variant: "success",
          });
        } else {
          enqueueSnackbar("Driver creation Failed:User Already Exist", {
            variant: "warning",
          });
        }
        // check
      }
    } catch (error) {
      enqueueSnackbar(`${error.message}`, { variant: "error" });
    }
  };

  

  return (
    <div className="newUser">
      <form
        action=""
        className="newUserform"
        onSubmit={handleSubmit}
        noValidate
      >
        <div className="newUserItem">
          {/* <label htmlFor=""> Address </label> */}
          <TextField
              variant="outlined"
              autoComplete="false"
              // value={data["quantity"]}
              type="text"
              label="Ticket holder name"
              id="name"
              required
              name="name"
              onChange={handleQuintityChange}
             // helperText="Should be in Litres"
              // fullWidth
            ></TextField>
            
        </div>

       
        <div className="newUserItem">
        <TextField
              variant="outlined"
              autoComplete="false"
              // value={data["quantity"]}
              type="text"
              label="Surname"
              id="surname"
              required
              name="surname"
              onChange={handleQuintityChange}
            //  helperText="Please enter a valid email adress to be used for sending the ticket"
              // fullWidth
            ></TextField>
        </div>

        <div className="newUserItem">
        <TextField
              variant="outlined"
              autoComplete="false"
              // value={data["quantity"]}
              type="text"
              label="Ticket holder email adress"
              id="email"
              required
              name="email"
              onChange={handleQuintityChange}
              helperText="Please enter a valid email adress to be used for sending the ticket"
              // fullWidth
            ></TextField>
        </div>
        

        <div className="newUserItem">
          <div className="newUserPassword">
            <Typography variant="h4">Phone Number:</Typography>
            <TextField
              variant="outlined"
              autoComplete="false"
              // value={data["quantity"]}
              type="number"
              label="Phone Number"
              id="phone"
              required
              name="phone"
              onChange={handleQuintityChange}
              helperText="Use format 771890111"
              // fullWidth
            ></TextField>
          </div>
        </div>

        <Button onClick = {handleNext} variant="contained" color = "primary" endIcon = {<NavigateNextIcon/>}> NEXT </Button>
       
      </form>
    </div>
  );
};

export default AdressForm;
