
import { Button, FormControl, Input, InputLabel, MenuItem, Paper, Select, TextField, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import FileBase64 from 'react-file-base64';
 import "./updateCollectionOffice.css";
import { cashaxiosInstance } from "../../../services/axios";




const UpdateCashOrders = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  let params = useParams();
  const [odernumber, setodernumber] = useState(params.officeId);
  const [state, setState] = React.useState('');
  const [formData, setformData] = useState({})
  const [upDateOrder, setupDateOrder] = useState({})
 const [data,setData]="some value"
 const [age, setAge] = React.useState('');

  // const handleChange = (e) => {
  //   setformData({ ...formData, [e.target.id]: e.target.value });
  //  // setformData({ ...formData, "newOfficeStatus":e.target.innerText });
  

  
  // };
  const handle = (e) => {
   // setData(e.target.innerText)
    console.log(e.target.innerText) // => This logs menu item text.
   
}
 
const handleChange = (event) => {
  setAge(event.target.value)
}



  const handleOrderChange = (e) => {
    setupDateOrder({ ...upDateOrder, [e.target.name]: e.target.value.trim() });
    console.log(upDateOrder)
  };

  const getFiles = ({base64}) =>{
    //1.upload the file to firebase
    //2.return the url
    //3.upload the url with other stuff
    let my_url = "Admin Url"
    console.log(base64)
    setupDateOrder({...upDateOrder,proof_url:my_url})
  }

  const handleSubmit = async(e) =>{
      e.preventDefault()
      
      try {
        let responce =  await cashaxiosInstance.post('/office/update',
        { "name":odernumber,"newOfficeStatus" : age})
        let my_responce = responce.data.success
        console.log(responce.data)

        if(my_responce === true){
            enqueueSnackbar('Update Successfull',{variant: "success"});
        }
        else{
            enqueueSnackbar('Something went Wrong',{variant: "warning"});

        }
          
      } catch (error) {
        enqueueSnackbar(`${error.message}`,{variant: "error"});
      }
  }

  const handleSubmit2 = async(e) =>{
    e.preventDefault()
    console.log(params.name)
    console.log(upDateOrder)
   console.log(document.getElementById('newOfficeStatus').value)
    try {
     
      let responce =  await cashaxiosInstance.post('/office/update',
      { "name":params.officeId,
      "newOfficeStatus":formData
        
      
    })
      let my_responce = responce.data.success
      

      if(my_responce === true){
          enqueueSnackbar('Order  Upadated Successfully',{variant: "success"});
      }
      else{
          enqueueSnackbar(`${responce.data.message}`,{variant: "warning"});

      }
        
    } catch (error) {
      enqueueSnackbar(`${error.message}`,{variant: "error"});
    }
}


  return (
    <div className="mainorderpage">
      <div className = "firstContainer">
      <Paper>
      <div className="myheading">
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
        >
          Update Collection Office
        </Typography>
        <Typography>
                Name :{`${odernumber}`}
              
        </Typography>
        <br/>
        
-

<FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Status</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={age}
          label="Status"
          onChange={handleChange}
        >
          <MenuItem value={"Limited"}>Limited</MenuItem>
          <MenuItem value={"Sufficient"}>Sufficient</MenuItem>
          <MenuItem value={"OutofStock"}>OutofStock</MenuItem>
        </Select>

        <br/>
          <hr/>
          <br/>
            <br/>
          <Button onClick = {handleSubmit} variant = "contained" color = "primary">
              Update
          </Button>
      </FormControl>
      </div>
      </Paper>
      </div>
 
    </div>
  );
};

export default UpdateCashOrders;
