import React from 'react'
import TransactionList from '../../components/Transactions/TransactionList'

const Transactions = () => {
    return (
        <div>
            <TransactionList/>
        </div>
    )
}

export default Transactions
