import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Grid,
  Paper,
  withStyles,
  Stepper,
  Step,
  StepLabel,
  Typography,
  Container,
} from "@material-ui/core";

import { styles } from "./styles";
import AdressForm from "../Checkout/AdressForm";
//import Review from "../Checkout/Review";
import Review from "../Checkout/Review";
import FinalStep from "./FinalStep";

class OrderCreation2 extends Component {
  state = {
    data: {
      truck:'',
      trailer:'',
      driver:{
        firname:'',
        lastname:'',
        passport:'',

      },
      office:'',
      quantity:'',
      total:'',
      price:10
    
    },
    errors: {},
    steps: [{ label: "Order Creation" }, { label: "Review Your Order" }],
    stepCount: 0,
  };
  render() {
    const { classes } = this.props;

    const handleSubmit = (e) => {
      e.preventDefault();
      const {data} = this.state
      console.log("data submitted",data);
    };

    const handleChange = (e, newvalue) => {
      if (newvalue !== null) {
        const { data } = this.state;
        data[newvalue.id] = newvalue.value;

        //   setFormData({ ...formData, [newvalue.id]: newvalue.value });
      }
    };
    const handleQuintityChange = (e) => {
        
      const { data } = this.state;
      data[e.target.id] = e.target.value;

      // setFormData({ ...formData, [e.target.id]: e.target.value });
    };

    // const handleOnChange = ({ target }) => {
    //   const { data } = this.state;
    //   data[target.name] = target.value;
    // };

    const handleNextStep = () => {
      let { stepCount } = this.state;
      console.log("stepCount", stepCount);
      stepCount = stepCount + 1;
      this.setState({ stepCount });
    };
    const handleBackStep = () => {
      let { stepCount } = this.state;
      stepCount = stepCount - 1;
      this.setState({ stepCount });
    };

    const getStepContent = (step) => {
      switch (step) {
        case 0:
          return (
            <AdressForm
              state={this.state}
              handleChange={handleChange}
              handleNext={handleNextStep}
              handleQuintityChange={handleQuintityChange}
            />
          );
        case 1:
          return (
            <Review
              state={this.state}
              handleChange={handleChange}
              handleNext={handleNextStep}
              handlePrev={handleBackStep}
              handleSubmit={handleSubmit}
            />
          );
        case 2:
          return <FinalStep state={this.state} />;
        default:
          return (
            <AdressForm
              state={this.state}
              handleChange={handleChange}
              handleNext={handleNextStep}
            />
          );
      }
    };

    return (
      <Container component="main" maxWidth="sm" sx={{ mb: 1 }}>

        <div sx={{ my: { xs: 1, md: 1 }, p: { xs: 1, md: 1} }}>
          <form onSubmit={this.handleSubmit} className={classes.form}>
              <div className style = {{"display":"flex","justifyContent":"center","fontWeight":"700w"}}> 
            <Typography variant="h6"> Order Creation Process</Typography>
            </div>

            <Stepper activeStep={this.state.stepCount} alternativeLabel>
              {this.state.steps.map((item) => (
                <Step key={item.label}>
                  <StepLabel>{item.label}</StepLabel>
                </Step>
              ))}
            </Stepper>

            {getStepContent(this.state.stepCount)}
          </form>
        </div>
      </Container>
    );
  }
}

OrderCreation2.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(OrderCreation2);
