import "./NewCashDeposit.css";

import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { axiosInstance } from "../../../../../services/axios";
import { cashaxiosInstance } from "../../../../../services/axios";
import Select from 'react-select'
import { useSnackbar } from "notistack";


export default function NewCashDeposit() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [open, setOpen] = React.useState(false);
  const [formData, setFormData] = useState({});
  const [users,setUsers] = useState ([])

  const handleSearchChange = (e) =>{
  
    setFormData({ ...formData, [e.id]: e.value.trim() });
    console.log(formData)
  }

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value.trim() });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData)

    try {
      let response = await cashaxiosInstance.post("/cash/load", formData);

      if (response) {
        let  success = response.data.success
        if(success === 'true'){
          enqueueSnackbar('Cash Deposit was successful',{variant: "success"});
        }else{
          enqueueSnackbar('Deposit was not successful :Deplicate Reference',{variant: "warning"});
        }
        // check for the errors

      }
    } catch (error) {
      enqueueSnackbar(`${error.message}`,{variant: "error"});


    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect( async () => {
    const response =  await axiosInstance.get("/admin/getusers")
    let users = response.data.data.users
    
    let customisedUsers = users.map(user =>({value :user.email,label:user.companyname,id : "clientId"}))
    setUsers(customisedUsers)
    
    
  }, [])

  return (
    <div>
      <Button onClick={handleClickOpen}>Load Cash Deposit</Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Load New Cash Deposit</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <DialogContentText>
              Please use the pre-populated reference below or your company name
              when making payments. Note: We may enable automatic payments in
              future whereafter your company name will not allow for an
              automatic deposit.
            </DialogContentText>
            
            <Select
              id = "select_user"
              placeholder = "Select User"
              name = "clientId"
              className="myField"
              onChange ={handleSearchChange}
             options={users} />
        

            <div className="myField">
              <TextField
                className="field"
                id="bank"
                name="bank"
                label="Bank"
                variant="outlined"
                fullWidth
                onChange={handleChange}
                // helperText = "eg. Harare Cash Office"
              />
            </div>

            <div className="myField">
              <TextField
                className="field"
                id="Amount"
                label="Amount"
                name="amount"
                variant="outlined"
                helperText="eg. 1234.56"
                fullWidth
                onChange={handleChange}
              />
            </div>

            <div className="myField">
              <TextField
                className="field"
                id="reference"
                label="Reference"
                name="reference"
                variant="outlined"
                fullWidth
                onChange={handleChange}
                // helperText = "eg.h7889"
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              Cancel
            </Button>
            <Button onClick={handleClose} color="primary" type="submit">
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
