import React, { useState } from "react";
import "./notifications.css";
// import TextareaAutosize from '@mui/base/TextareaAutosize';
import TextField from "@material-ui/core/TextField";
import { Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextareaAutosize } from "@material-ui/core";
import { cashaxiosInstance } from "../../services/axios";
import { useSnackbar } from "notistack";

const Notifications = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [formData, setFormData] = useState({});

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value.trim() });
    console.log(formData)
  };

 const handleOnChange=(event)=>{
    this.setState({
      textareaValue: event.target.value
    })
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let response = await cashaxiosInstance.post("/notification/create",  {"notification":document.getElementById('notification').value
    });
      if (response) {
        let success = response.data.success;
        console.log(response.data);
        if (success === true) {
          enqueueSnackbar("Notification created successfully", {
            variant: "success",
          });
        } else {
          enqueueSnackbar("Please try again", {
            variant: "warning",
          });
        }
        // check
      }
    } catch (error) {
      enqueueSnackbar(`${error.message}`, { variant: "error" });
    }
  };

  return (
    <div className="newUser">
      <h1 className="newUserTitle">New Notification</h1>
      <form  action="" className="newUserform" >
        <div className="newUserItem">
          <TextareaAutosize
          id="notification"
            variant="outlined"
            label="Notification"
            minRows={10}
            name="notification"
            
            required
            fullWidth
            placeholder="Please enter your notification to the client"
            onChange={handleChange}
          />
        </div>
        <div className="newUserItem">
          <div className="userButton">
            <Button variant="contained" color="primary" type="submit" onClick={handleSubmit}>
              Create Notification
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Notifications;
