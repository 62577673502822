import { Button, FormControl, Input, InputLabel, MenuItem, Paper, Select, TextField, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import FileBase64 from 'react-file-base64';
 import "./updateStatus.css";
import { axiosInstance } from "../../../services/axios";



const UpdateCashOrders = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  let params = useParams();
  const [odernumber, setodernumber] = useState(params.siteId);
  const [state, setState] = React.useState('');
  const [formData, setformData] = useState({})
  const [upDateOrder, setupDateOrder] = useState({})

  const [age, setAge] = React.useState('');
  const handleChange = (event) => {
    setAge(event.target.value)
  }
  

  const handleOrderChange = (e) => {
    setupDateOrder({ ...upDateOrder, [e.target.name]: e.target.value.trim() });
    console.log(upDateOrder)
  };

  const getFiles = ({base64}) =>{
    //1.upload the file to firebase
    //2.return the url
    //3.upload the url with other stuff
    let my_url = "Admin Url"
    console.log(base64)
    setupDateOrder({...upDateOrder,proof_url:my_url})
  }

  const handleSubmit = async(e) =>{
    e.preventDefault()
    
    try {
      let responce =  await axiosInstance.post('/fuelingSite/update',
      { "name":odernumber,"newStatus" : age})
      let my_responce = responce.data.success
      console.log(responce.data)

      if(my_responce === true){
          enqueueSnackbar('Update Successfull',{variant: "success"});
      }
      else{
          enqueueSnackbar('Something went Wrong',{variant: "warning"});

      }
        
    } catch (error) {
      enqueueSnackbar(`${error.message}`,{variant: "error"});
    }
}


  

  return (
    <div className="mainorderpage">
      <div className = "firstContainer">
      <Paper>
      <div className="myheading">
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
        >
          Update Fueling Site
        </Typography>
        <Typography>
                Name :{`${odernumber}`}
        </Typography>
        <br/>
        
        <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Status</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={age}
          label="Status"
          onChange={handleChange}
        >
          <MenuItem value={"Limited"} className ="Limited">Limited</MenuItem>
          <MenuItem value={"Sufficient"} className ="Sufficient">Sufficient</MenuItem>
          <MenuItem value={"OutofStock"} className ="OutofStock">OutofStock</MenuItem>
        </Select>

        <br/>
          <hr/>
          <br/>
            <br/>
          <Button onClick = {handleSubmit} variant = "contained" color = "primary">
              Update
          </Button>
      </FormControl>
       
          
      </div>
      </Paper>
      </div>
 
    </div>
  );
};

export default UpdateCashOrders;
