import './AllDeposits.css'
import {  useEffect, useState } from 'react'
import { DataGrid } from '@material-ui/data-grid';
import {Link} from 'react-router-dom'
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

import NewDeposit from './NewDeposit/NewDeposit';

import React from 'react'
import { deposits } from '../../utils/graphData'
import { Typography } from '@material-ui/core';
import { axiosInstance } from '../../services/axios';
import MaterialTable from 'material-table';
import numberFormat from '../../utils/CurrencyFormat';
import formartDate from '../../utils/formatDate';
import { ExportToCsv } from 'export-to-csv'; 
import { Box, Button } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import { StoreContext } from '../../utils/store'
import {
  useParams,
  useLocation,
  useHistory,
  useRouteMatch,
} from "react-router-dom";



export default function AllDeposits() {

  let params = useParams();

 const [tname, setTname] = useState(params.name);
 const [tid, setTid] = useState(params.tid);
  const idn =localStorage.getItem("idnum");
 
  const pk=3

  const { pairing } = React.useContext(StoreContext)
    const [orders, setOrders] = useState([])
    const [cont, setCont] = useState([])
    const [usd, setUSD] = useState([])
    const [zwl, setZwl] = useState([])
    const handleDelete = (id) =>{
        setOrders(orders.filter((item) => item.id !== id));
    }
    useEffect( async () => {
      let get_all_deposits =  async () =>{
        try {
          const response =  await axiosInstance.post("/admin/transid",{"tid":tname})
          let deposits = response.data.data.transactions;
         let c = response.data.data.count;
         let  u =response.data.data.USD;
          let z =response.data.data.ZWL;

           setCont(c)
           setUSD(u)
           setZwl(z)
      
  console.log(response.data.data.USD)
        let customiseDeposits = deposits.map( function(deposit) {
          // let mdate = deposit.date
          // mdate.toLocaleTimeString()
          console.log(deposit)
          
          
          return{
          ...deposit,
          id:deposit._id,
          date: formartDate(deposit.datecreated),
          
          amount:deposit.amount,
          name:deposit.name,
        surname:deposit.surname,
          phone:deposit.phone,
          idnum:deposit.idnum,
          email:deposit.email,
          ticketnum:deposit.transid,
          
          doneby:deposit.districtname
  
        }
      })
        console.log(customiseDeposits)
        setOrders(customiseDeposits)
          
        } catch (error) {
          console.log(error)
          
        }
        
      }
      get_all_deposits()
      
    }, [])
    
    
  

    const columns = [
        { field: 'id',title: 'ID', width: 20,hidden:true },
        {
          field:'date',
        title: 'Date & Time',
          width: 150,
         
        },
        // {
        //   field:'doneby',
        // title: 'District',
        //   width: 150,
         
        // },
        {
          field: 'ticketnum',
        title: 'Ticket #',
          width: 100,
    
        },
        {
          field: 'name',
        title: 'Name',
          width: 100,
    
        },
        {
          field: 'surname',
        title: 'Surname',
          width: 100,
    
        },
        {
          field: 'phone',
        title: 'Phonenumber',
          width:150,
         
      },
      {
        field: 'idnum',
      title: 'ID#',
        width:180,
       
    },
   
    {
      field: 'email',
    title: 'Email',
      width:150,
     
  },
  {
    field: 'size',
  title: 'Size',
    width:180,
   
},
        {
            field: 'amount',
          title: 'Amt',
            width:150,
           
        },
        {
          field: 'currency',
        title: 'CC',
          width:150,
         
      },
        // {
        //   field: 'status',
        // title: 'Status',
        // //   description: 'This column has a value getter and is not sortable.',
        // render: (params) => {
        //   return (
        //     <> 
        //     <p 
        //     className = {params.status}
        //     >
        //     {params.status}
        //     </p>
            
        //     </>
          
        //   );
        // },
        // },
        // {
        //     field: 'declineReason',
        //   title: 'Decline Reason',
        //   //   description: 'This column has a value getter and is not sortable.',
        //     width: 150,
        // },
        // {
        //     field: 'loadedBy',
        //   title: 'Loaded-By',
        //   //   description: 'This column has a value getter and is not sortable.',
        //     width: 200,
        // },
        // {
        //     field: 'bank',
        //   title: 'Bank',
        //   //   description: 'This column has a value getter and is not sortable.',
        //     width: 200,
        // },
        // {
        //     field: 'reference',
        //   title: 'Ref',
        //   //   description: 'This column has a value getter and is not sortable.',
        //     width: 100,
        // },
        // {
        //   field:"id",
        //   title:"Action",
        //     width:150,
        //     render:(params)=>{
        //         return(
        //             <>
        //                 <Link to = {"/deposit/"+params.id}>
        //                     <button className = "editDeposit btn">Edit</button>
        //                 </Link>

                       
                        
                       
        //             </>
        //         )
        //     },
        // }
      ];
      const columnss = [
 
   
        // {
        //   field:'doneby',
        // title: 'District',
        //   width: 150,
         
        // },
        {
          field: 'ticketnum',
          headerName: 'ticketnum',
          width: 100,
    
        },
        {
          field: 'name',
          headerName: 'name',
          width: 100,
    
        },
        {
          field: 'surname',
          headerName: 'surname',
          width: 100,
    
        },
        {
          field: 'phone',
          headerName: 'phone',
          width:150,
         
      },
      {
        field: 'idnum',
        headerName: 'idnum',
        width:180,
       
    },
   
    {
      field: 'email',
      headerName: 'email',
      width:150,
     
  },
  
        {
            field: 'amount',
            headerName: 'amount',
            width:150,
           
        },
      
     
     
    
     
    ];
  
      const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        headers: columnss.map((c) => c.headerName),
      };
      
      const csvExporter = new ExportToCsv(csvOptions);
      
  
      const handleExportData = () => {
        csvExporter.generateCsv(orders);
      };
    
    return (
        <div className= "depositList">
               <div className ="featured">
            <div className="featureItem">
                <span className="featuredTitle">
                    Tickets Sold
                </span>
                <div className="featureContainer">
                    <span className="total">{cont}</span>
                    
                </div>
               
            </div>
          

            <div className="featureItem">
                <span className="featuredTitle">
                    Revenue Collected
                </span>
                <div className="featureContainer">
                    <span className="total"> {"USD:"+usd+"..."+"ZWL:"+zwl}</span>
                    
                </div>
               
            </div>

         <div></div>
        </div>
            <div className="topPart"> 
                <div className="title">
                    <Typography variant ="h5">Ticket Name :{tname}</Typography>
               
                </div>
                <Button
            color="primary"
            //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
            onClick={handleExportData}
            startIcon={<FileDownloadIcon />}
            variant="contained"
          >
            Export All Data
          </Button>
            </div>
            <div className = "bottomPart">
            <MaterialTable
              title = "All Tickets"
                data={orders}
                columns={columns}
                options= {{
                  maxBodyHeight:"60vh",
                  pageSize:5,
                  exportButton: true,
                  headerStyle: {
                    backgroundColor: '#01579b',
                    color: '#FFF',
                    display:"fixed"
                 }
      
                }}
            />

            </div>
            
        </div>
    )
    
}


