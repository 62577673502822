import { blueGrey } from "@material-ui/core/colors";

export const styles = {
  formContainer: {
    display: "flex",
    // flexFlow: "row Wrap",
    justifyContent: "center",
    // alignItems: "center",
    // minWidth:"350px"
    // height: "98vh",
  },

  form: {
    // padding: "10px",
    // minHeight: "350px",
    // height: "auto",
  },
  formTitle: {
    color: blueGrey[700],
    textAlign: "center",
    // margin: "10px 0px 30px 0px",
  },

  steps: {
    padding: "16px",
  },
};
