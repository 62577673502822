import { DataGrid } from "@material-ui/data-grid";
import React, { useEffect, useState } from "react";
import { axiosInstance } from "../../services/axios";
import MaterialTable from "material-table";
import numberFormat from "../../utils/CurrencyFormat";

const columns = [

  { field: "id", title: "ID", width: 90 , hidden:true },
  {
    field: "companyName",
    title: "Company Name",
    
  },

  {
    field: "collectionOffice",
    title: "Collection Office",
  
    
  },

  {
    field: "price",
    title: "Price",
    
  },
];


const PriceList = () => {
  const [prices, setPrices] = useState();
  useEffect(() => {
    let get_all_prices = async () => {
      try {
        const price_response = await axiosInstance.get(
          "/admin/prices/getAllPrices"
        );
        let my_prices = price_response.data.data.prices;
        let customisedPrices = my_prices.map((price)=>({
          ...price,
          price:numberFormat(price.price)

        }))

        setPrices(customisedPrices);
      } catch (error) {
        console.log(error.message);
      }
    };
    get_all_prices();
  }, []);

  return (
    <div>
      <MaterialTable
        title ={`Price List As At :${new Date().toLocaleDateString()}`}
          data={prices}
          disableSelectionOnClick
          columns={columns}
          options={{
            pageSize:5,
              exportButton: true,
              maxBodyHeight:"60vh",
              headerStyle: {
                backgroundColor: '#01579b',
                color: '#FFF',
                display:"fixed"
             }
          }}
        />
     
    </div>
  );
};

export default PriceList;
